import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import hero from '../../images/issues/improve-quality-of-life/improve-quality-of-life-hero.webp'
import accomplishment1 from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-1.webp'
import accomplishment2 from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-2.webp'
import accomplishment2A from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-2a.webp'
import accomplishment2B from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-2b.webp'
import accomplishment2C from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-2c.webp'
import accomplishment2D from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-2d.webp'
import accomplishment2E from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-2e.webp'
import accomplishment2F from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-2f.webp'
import accomplishment2G from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-2g.webp'
import accomplishment2H from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-2h.webp'
import accomplishment2I from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-2i.webp'
import accomplishment2J from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-2j.webp'
import accomplishment3A from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-3a.webp'
import accomplishment3B from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-3b.webp'
import accomplishment3C from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-3c.webp'
import accomplishment3D from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-3d.webp'
import accomplishment3E from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-3e.webp'
import accomplishment4 from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-4.webp'
import accomplishment5 from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-5.webp'
import accomplishment6A from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6a.webp'
import accomplishment6B from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6b.webp'
import accomplishment6C from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6c.webp'
import accomplishment6D from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6d.webp'
import accomplishment6E from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6e.webp'
import accomplishment6F from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6f.webp'
import accomplishment6G from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6g.webp'
import accomplishment6H from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6h.webp'
import accomplishment6I from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6i.webp'
import accomplishment6J from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6j.webp'
import accomplishment6K from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6k.webp'
import accomplishment6L from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6l.webp'
import accomplishment6M from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6m.webp'
import accomplishment6N from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6n.webp'
import accomplishment6O from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-6o.webp'
import accomplishment7A from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-7a.webp'
import accomplishment7B from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-7b.webp'
import accomplishment7C from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-7c.webp'
import accomplishment7D from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-7d.webp'
import accomplishment7E from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-7e.webp'
import accomplishment7F from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-7f.webp'
import accomplishment7G from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-7g.webp'
import accomplishment7H from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-7h.webp'
import accomplishment7I from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-7i.webp'
import accomplishment8A from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-8a.webp'
import accomplishment8B from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-8b.webp'
import accomplishment8C from '../../images/issues/improve-quality-of-life/improve-quality-of-life-accomplishment-8c.webp'

let issueDetailPageHero = 'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80'
let accomplishmentImgPlaceholder = 'http://placehold.it/502x282'

const IssuesImproveQualityOfLifePage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Issues - Improve Quality of Life" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div>
          <img
              className="w-full max-h-96 object-cover object-pos-0-40" // TODO: figure out aspect ratio -- trying to set 2:1
              src={hero}
              alt=""
            />
        </div>
        <div className="pt-10 max-w-5xl mx-auto">
          <h1 className="font-bold text-6xl">Improve Quality of Life</h1>
          <p className="text-2xl leading-relaxed">
            Rhode Island is at a critical juncture in our history. To unite and move our state forward, we need bold and responsive leadership. Additionally, we need a clear vision and an unwavering commitment to turn our bold ideas into action.
          </p>
          <p className="text-2xl leading-relaxed">
            As a lifelong Rhode Island resident, I know all too well the challenges that have kept the state from reaching its highest potential. It's time to aggressively tackle our systemic challenges head-on and this requires a clear plan with real, implementable solutions.
          </p>
          <p className="text-2xl leading-relaxed">
            More than ever, we must stand united to deliver for the people while taking pride in our state and actively making it the most beautiful, creative state we know it can be. 
          </p>
          <p className="text-2xl leading-relaxed">
            As Congressman, I will continue to be a strong champion for our state, addressing quality of life issues, education, public health, and public safety, and advocating for expanding jobs and economic opportunities in the state.
          </p>
          <p className="text-2xl leading-relaxed">
            As someone who is deeply passionate about creating meaningful, sustainable change that benefits all of our residents, I've been committed to protecting and enhancing the quality of life in our neighborhoods and improving services for all state residents.
          </p>
          <p className="text-2xl leading-relaxed">
            Yet we have a long way to go. I believe that all of our residents have a fundamental right to live with dignity, access to economic resources, social inclusion, and the greatest quality of life. As such, these plans articulate a vision to help us tackle the quality of life challenges before us and ultimately build a cleaner, safer, and more prosperous state.
          </p>
          <p className="text-2xl leading-relaxed">
            To do this, we must eliminate any complacency in our federal government. With a more effective and efficient government spearheaded by persistent leadership, we can enable residents, businesses, and organizations to thrive and be as successful as they can be.
          </p>
          <p className="text-2xl leading-relaxed">
            Ultimately, our vision is to secure a better future for all, and the people of Rhode Island deserve nothing less. We must model this leadership and advocate for the cleanest and safest streets the state has ever seen.  We must foster a sense of ownership and participation among all of our residents and all chip in to do our part.
          </p>
          <p className="text-2xl leading-relaxed">
            Together, we can create the “world-class” state that we love and take pride in. So let's get to work.  Here is an example <a href="/issues/improve-quality-of-life/plan">quality of life plan</a> for the city of Providence. 
          </p>
        </div>
        <div className="max-w-5xl mx-auto pt-10">
          <span className="text-base tracking-widest uppercase">John's Record</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl pb-10">What We've Accomplished Together</h2>
          {/*TODO: L side - bullet point, R side - image - TEXT John when I'm working on this, Sanctuary, John visiting Boston - Mass & Cass, etc.*/}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <h3 className="font-bold text-xl">Resolved Thousands and Thousands of Constituent Service Issues</h3>
              <p>• Successfully <strong>closed and resolved thousands and thousands of constituent services tickets</strong> related to public safety, park maintenance, trash collection, snow removal, tree-related issues, graffiti, new street signs, potholes, sidewalk repairs, permitting issues, taxes, etc. </p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Fought To Keep The Ward Clean and Safe</h3>
              <p>• Councilman John Goncalves aggressively tackled graffiti that’s amassed over the years before his term. Additionally, he refused to be complacent and took an assertive role in cleaning up areas that have been historically neglected. Councilman John Goncalves demanded the highest expectations from Department of Public Works employees, sponsored nearly two dozen neighborhood clean-ups, and took a hands-on approach to keep the Ward clean.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2A}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2B}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2C}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2D}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2E}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2F}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2G}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2H}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2I}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2J}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Made Government More Accessible &amp; Brought Government To The People</h3>
              <p>• Substantially improved communications with constituents by holding monthly meetings and dozens of town halls where residents could voice their ideas and concerns directly to department heads in order to improve quality of life. Brought government to the people by having department heads attend our meeting such as the Director of the Department of Public Works, The City Traffic Engineer,  Superintendent of the Providence Department of Parks + Recreation, The Director of Providence Emergency Management Agency, The Director of Sustainability &amp; more. Special guests included Congressman David Cicilline, Treasurer Seth Magaziner, The Mayor of Providence, Infectious disease physician Dr. Philip A. Chan &amp; a slew of other experts, leaders, and elected officials.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3A}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3B}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3C}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3D}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3E}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Dramatically Expanded Communication &amp; Information Sharing To Improve Quality of Life</h3>
              <p>• Sent over 1.2 million total emails to constituents, including daily updates in the height of the pandemic to keep our community informed and safe. Expanding communication allowed us to engage residents and open the lines of communication allowing us to share information and rapidly respond to neighborhood issues.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment4}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Author and LEAD sponsor of over 50+ pieces of <em>PASSED</em> legislation</h3>
              <p>• Authored and sponsored over 50 pieces of passed legislation to improve quality of life related to housing/zoning issues, environmental and economic justice, public safety, schools, city finances, basic city services.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment5}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Launched Initiatives To Build Community</h3>
              <p>• Launched and spearheaded important neighborhood events and initiatives with community partners such as Produce In The Parks, Autumn Air In The Square, Ward 1 neighborhood clean-ups, Ward 1 Block Parties, Ward 1 Amusement Park, Ward 1 Movies In The Park &amp; more. These events and initiatives brought our vibrant community together.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6A}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6B}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6C}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6D}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6E}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6F}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6G}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6H}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6I}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6J}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6K}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6L}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6M}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6N}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment6O}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Improved Quality of Life For Our Children &amp; Seniors</h3>
              <p>• Took on our housing issues, spearheaded the City’s environmentally preferable purchasing policy, tackled food insecurity in our Ward, fought for millions in funding, advocated tirelessly for our children, families, and seniors.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment7A}
                alt=""
              />
              </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment7B}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment7C}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment7D}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment7E}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment7F}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment7G}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment7H}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment7I}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Promoted Ward 1 As A Truly Inclusive Community Where Everyone Matters and Everyone Counts</h3>
              <p>• Authored dozens of inclusive policies that affirmed and championed our LGBTQIA+, BIPOC, and AAPI communities.</p>
              <p>• Lead sponsor on passed legislation recognizing International Transgender Day of Remembrance. Read more <a href="https://providenceri.iqm2.com/Citizens/Detail_LegiFile.aspx?Frame=&MeetingID=12305&MediaPosition=&ID=30376&CssClass=" target="_blank">here</a>.</p>
              <p>• Lead sponsor on passed legislation Recognizing International Holocaust Remembrance Day. Read more <a href="https://providenceri.iqm2.com/Citizens/Detail_LegiFile.aspx?Frame=&MeetingID=12829&MediaPosition=&ID=31001&CssClass=" target="_blank">here</a>.</p>
              <p>• Lead sponsor condemning AAPI Attacks &amp; Denouncing Xenophobic Violence in light of the COVID pandemic. During this time, it was important to stand in solidarity with Asian American and Pacific Islander (AAPI) community and explicitly condemn Asian hate and discrimination in our City. Read more <a href="https://providenceri.iqm2.com/Citizens/Detail_LegiFile.aspx?Frame=&MeetingID=12833&MediaPosition=&ID=31427&CssClass=" target="_blank">here</a></p>
              <p>• Lead sponsor on passed legislation that helped urge the Governor and RIPTA to reopen Kennedy Plaza bathrooms for bus riders because it was the right thing to do. Read more <a href="https://providenceri.iqm2.com/Citizens/Detail_LegiFile.aspx?Frame=&MeetingID=12838&MediaPosition=&ID=32046&CssClass=" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment8A}
                alt=""
              />
              </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment8B}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment8C}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto pt-10 pb-10">
          <span className="text-base tracking-widest uppercase">Our Policy Platform Moving Forward</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl">The Work We Still Have To Do</h2>
          <p className="text-xl mt-5">
            Rhode Island is at a critical juncture in our history. To unite and move our state forward, we need bold and responsive leadership. Additionally, we need a clear vision and an unwavering commitment to turn our bold ideas into action.
          </p>
          <p className="text-xl mt-5">
            As a lifelong Rhode Island resident, I know all too well the challenges that have kept the state from reaching its highest potential. It's time to aggressively tackle our systemic challenges head-on and this requires a clear plan with real, implementable solutions.
          </p>
          <p className="text-xl mt-5">
            <strong>More than ever, we must stand united to deliver for the people while taking pride in our state and actively making it the most beautiful, creative state we know it can be.</strong>
          </p>
          <p className="text-xl mt-5">
            As Congressman, I will continue to be a strong champion for our state, addressing quality of life issues, education, public health, and public safety, and advocating for expanding jobs and economic opportunities in the state.
          </p>
          <p className="text-xl mt-5">
            As someone who is deeply passionate about creating meaningful, sustainable change that benefits all of our residents, I've been committed to protecting and enhancing the quality of life in our neighborhoods and improving services for all state residents.
          </p>
          <p className="text-xl mt-5">
            <strong>Yet we have a long way to go.</strong> All of our residents have a fundamental right to live with dignity, access to economic resources, social inclusion, and the greatest quality of life. As such, these plans articulate a vision to help us tackle the quality of life challenges before us and ultimately build a cleaner, safer, and more prosperous state.
          </p>
          <p className="text-xl mt-5">
            To do this, we must eliminate any complacency in our federal government. With a more effective and efficient government spearheaded by persistent leadership, we can enable residents, businesses, and organizations to thrive and be as successful as they can be.
          </p>
          <p className="text-xl mt-5">
            Ultimately, our vision is to secure a better future for all, and the people of Rhode Island deserve nothing less. We must model this leadership and advocate for the cleanest and safest streets the state has ever seen. We must foster a sense of ownership and participation among all of our residents and all chip in to do our part.
          </p>
          <p className="text-xl mt-5">
            <strong>Together, we can create the “world-class” state that we love and can all take pride in. So let's get to work.</strong>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IssuesImproveQualityOfLifePage